import type { LinkProps as RemixLinkProps } from "@remix-run/react";
import { Link as RemixLink } from "@remix-run/react";
import { AppUrl } from "@tamarack/shared/AppUrl";
import { useFilterValuesState } from "./hooks/useFilterValuesState";

export type BaseLinkProps<T> = Omit<T, "to"> & {
  to: AppUrl;
  /**
   * The search params with the "to" value should supercede the values stored in the filter
   * params state (local storage)
   *
   * @default false
   */
  overrideFilterState?: boolean;
};

export type LinkProps = BaseLinkProps<RemixLinkProps>;

export default function Link({ to, overrideFilterState = false, ...linkProps }: LinkProps) {
  const url = useLinkUrl(to, overrideFilterState);

  return <RemixLink to={url.toString()} prefetch="intent" {...linkProps} />;
}

export function useLinkUrl(to: AppUrl, overrideFilterState = false) {
  let url = new AppUrl(to);
  const { getFilterValue } = useFilterValuesState(url.pathname);
  const pageFilterValues = getFilterValue();

  if (!overrideFilterState) {
    url = url.mergeSearchParamsFromObject(pageFilterValues);
  }

  return url;
}
